import React, { useContext }       from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
}                                  from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';

import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import SEO                           from '@interness/web-core/src/components/modules/SEO/SEO';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Gallery          from '@interness/ecommerce-addon/src/components/Gallery/Gallery';
import Error            from '@interness/ecommerce-addon/src/components/Error/Error';
import PriceTag         from '@interness/ecommerce-addon/src/components/PriceTag';
import AddToCartButton  from '@interness/ecommerce-addon/src/components/AddToCartButton/AddToCartButton';
import ShippingTime     from '@interness/ecommerce-addon/src/components/ShippingTime';
import Tags             from '@interness/ecommerce-addon/src/components/Tags/Tags';
import Availability     from '@interness/ecommerce-addon/src/components/Availability';
import RatingStars      from '@interness/ecommerce-addon/src/components/ProductRatings/RatingStars';
import CallToAction     from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import { StoreContext } from '@interness/ecommerce-addon/src/context/Store';
import RequestForm      from '@interness/ecommerce-addon/src/components/RequestForm/RequestForm';


const ProductDetails = (props) => {
  const { site } = useStaticQuery(query);
  const { storeRootPath } = useContext(StoreContext);
  const { currentProduct, loading, error, settings } = props;

  if (loading || !currentProduct.details) {
    return <SpinnerContainer><Spinner/></SpinnerContainer>
  }

  if (error) {
    return <Error>Produkt konnte nicht geladen werden.</Error>
  }

  if (!currentProduct.details) {
    return <p>Produkt nicht gefunden</p>
  }

  const onClickRequest = async () => {
    const el = document.getElementById('int-item-request');
    window.scrollTo({
      top: el.offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <SEO title={currentProduct.details.display_name}/>
      <Spacer height={30}/>
      <Wrapper>
        <Flex flexWrap="wrap">
          <Button mr={4} colorScheme="brand" variant="outline" onClick={() => window.history.back()}>Zurück</Button>
          <Button colorScheme="brand" variant="solid" as={Link} to={storeRootPath} sx={{ textDecoration: 'none' }}>Alle
            Produkte</Button>
        </Flex>
      </Wrapper>
      <Spacer height={30}/>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Box width={{ base: '100%', lg: '50%' }} pt={[4, 4, 4, 4]} pr={[4, 4, 4, 4]} pb={[4, 4, 4, 0]} pl={[4, 4, 4, 0]}
             sx={{
               img: {
                 margin: '0 auto 0 auto',
               },
             }} bg="base.600">
          <Gallery images={currentProduct.details.images}/>
        </Box>
        <Box width={{ base: '100%', lg: '50%' }} p={4}>
          <Heading style={{ textTransform: 'uppercase' }} size="xl">{currentProduct.details.display_name}</Heading>
          {settings.allow_ratings && currentProduct.ratingsCount !== 0 && currentProduct.averageRating !== 0 &&
            <RatingStars rating={currentProduct.averageRating} count={currentProduct.ratingsCount} showTooltip/>}
          <Spacer height={30}/>
          <Text color="brand.500" fontWeight="bold" fontSize="xl">
            <PriceTag price={currentProduct.priceBrutto}
                      priceRange={currentProduct.variantPriceRange}/>
          </Text>
          <>
            {settings.enable_cart && (!currentProduct.hasVariants && currentProduct.priceBrutto !== 0) || (settings.purchase_to_order && currentProduct.reorderPossible)
              ? <AddToCartButton/>
              : site.siteMetadata.hasCalCom
                  ? <Button colorScheme="brand" as={Link} to={'/terminvereinbarung'} sx={{ textDecoration: 'none' }}>Zur
                    Terminvereinbarung</Button>
                  : <Button colorScheme="brand" onClick={onClickRequest}>Anfragen</Button>
              }
          </>
          <Spacer/>
          <Table size="sm">
            <Tbody sx={{
              hyphens: 'auto',
              tr: {
                display: 'flex',
                flexWrap: 'wrap',
              },
            }}>
              {settings.show_stock && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Verfügbarkeit</b></Td>
                <Td w={['100%', '100%', '50%']}><Availability/></Td>
              </Tr>}
              {/* Shipping Time */}
              {settings.enable_cart && currentProduct.stock >= 0 && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Lieferzeit</b></Td>
                <Td w={['100%', '100%', '50%']}>
                  <ShippingTime/>
                </Td>
              </Tr>}
              {/* Manufacturer */}
              {currentProduct.details.manufacturer && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Kollektion</b></Td>
                <Td
                  w={['100%', '100%', '50%']}>{currentProduct.details.manufacturer ? currentProduct.details.manufacturer.display_name : 'Hersteller unbekannt'}</Td>
              </Tr>}
              {currentProduct.details.tags.length !== 0 && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Schlagwörter</b></Td>
                <Td w={['100%', '100%', '50%']}><Tags tags={currentProduct.details.tags}/></Td>
              </Tr>}
              {/* Attributes */}
              {currentProduct.details.attributes.length > 0 && currentProduct.details.attributes.map(attribute => {
                return (
                  <Tr key={attribute.attribute_group.display_name}>
                    <Td w={['100%', '100%', '50%']}><b>{attribute.attribute_group.display_name}</b></Td>
                    <Td w={['100%', '100%', '50%']}>{attribute.display_values}</Td>
                  </Tr>
                )
              })}
              {currentProduct.details.reference_number && <Tr>
                <Td w={['100%', '100%', '50%']}><b>Referenz</b></Td>
                <Td
                  w={['100%', '100%', '50%']}>{currentProduct.details.reference_number}{currentProduct.variantSelected ? `.${currentProduct.variantSelected.reference_number_suffix}` : null}</Td>
              </Tr>}
            </Tbody>
          </Table>
          {currentProduct.details && currentProduct.details.description &&
            <Text dangerouslySetInnerHTML={{ __html: currentProduct.details.description }}/>}
        </Box>
      </Flex>
      <Box pt={4} borderTop="15px solid" borderColor="base.600">
        <Wrapper>
          {site.siteMetadata.hasCalCom && <CallToAction id="int-item-request"
                                                               description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung"
                                                               button="Zur Terminvereinbarung"
                                                               link="/terminvereinbarung"/>}
          {!site.siteMetadata.hasCalCom &&
            <RequestForm product={currentProduct.details} cta={settings.call_to_action_text}/>}
        </Wrapper>
      </Box>
    </>
  )
};

ProductDetails.propTypes = {};

export default ProductDetails;

const query = graphql`
    query {
        site {
            siteMetadata {
                hasCalCom
            }
        }
    }
`;